import request from "../../utils/request";

/**业务调配
 * @param {int} params.cId         -业务ID
 * @param {int} params.newVehId    -分配车辆ID
 * @param {int} params.oldVehId    -原车辆ID
 * @param {int} params.trainAdd    -分配时需增加的秒数
 * @param {int} params.userId      -用户ID
 */
export function allocateVeh(params) {
    return request("/monitor/allocateVeh", {
      method: "post",
      params: params
    });
  }  
 
/**获取本车当天未完成业务列表
 * @param {int} params.unitId      -单位ID
 * @param {int} params.vehId       -分配车辆ID
 */
export function getInCompleteWork(params) {
    return request("/monitor/getInCompleteWork", {
      method: "get",
      params: params
    });
  }    

/**获取本车当天所有业务列表
 * @param {int} params.unitId      -单位ID
 * @param {int} params.vehId       -分配车辆ID
 */
export function getTodayWork(params) {
    return request("/monitor/getTodayWork", {
      method: "get",
      params: params
    });
  }    
 
/**获取考试车(含设备信息)列表
 * @param {int} params.pageIndex   -页索引
 * @param {int} params.pageSize    -页容量
 * @param {int} params.stage       -科目
 * @param {int} params.starId      -星级ID
 * @param {int} params.status      -状态 0 正常 1 注销
 * @param {int} params.unitId      -单位ID
 * @param {int} params.veh         -车牌号
 * @param {int} params.vehNo       -车号
 * @param {int} params.vehType     -车型
 */


/**获取考试车(含设备信息)列表返回值
 *unifyCode  -统一编码
 *trainStart -角色类型 1 培训 0 考试
 *trainType  -油电开关 1 功能开 0 功能关
 *devId      -设备ID
 *IMEI       -设备唯一标识
 */

export function getVehListInnerDev(params) {
    return request("/monitor/getVehListInnerDev", {
      method: "get",
      params: params
    });
  }      

/**发送屏显语音消息
 * @param {int} params.devId       -设备ID
 * @param {int} params.start       -油电开关 1 功能开 0 功能关
 */
export function sendText(params) {
    return request("/monitor/sendText", {
      method: "post",
      params: params
    });
  }  

/**车辆油电开关转换
 * @param {int} params.devId       -设备ID
 * @param {int} params.start       -油电开关 1 功能开 0 功能关
 */
export function toggleFuncStart(params) {
    return request("/monitor/toggleFuncStart", {
      method: "post",
      params: params
    });
  }    
/**车辆油电开关转换
 * @param {int} params.devId       -设备ID
 * @param {int} params.type       -考试状态 1 功能开 0 功能关
 */
export function toggleTrainType(params) {
  return request("/monitor/toggleTrainType", {
    method: "post",
    params: params
  });
} 
/**获取本车设备管理密码
 * @param {int} params.devNum   -统一编号
 * @param {int} params.imei     -设备imei 
 */
export function getAdminCode(params) {
    return request("/monitor/getAdminCode", {
      method: "get",
      params: params
    });
  }   
/**设置设备管理员密码
 * @param {int} params.code     -管理员密码(8)
 * @param {int} params.devId    -设备ID
 * @param {int} params.devNum   -统一编号
 * @param {int} params.devNum    -设备imei 
 */
export function setAdminCode(params) {
  return request("/monitor/setAdminCode", {
    method: "post",
    params: params
  });
}   
/**获取本车设备油电和状态
 * @param {int} params.devNum   -统一编号
 * @param {int} params.imei    -设备imei 
 */
export function getLockAndType(params) {
  return request("/monitor/getLockAndType", {
    method: "get",
    params: params
  });
} 

/**获取车辆在线状态
 * @param {int} params.unitId   -单位id
 */
export function getLastLocation(params) {
  return request("/monitor/getLastLocation", {
    method: "get",
    params: params
  });
} 

/**坐标转换
 * @param {int} params.lat    -维度
 * @param {int} params.lon    -经度
 */
export function earth2Mars(params) {
  return request("/monitor/earth2Mars", {
    method: "get",
    params: params
  });
} 