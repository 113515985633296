/*
 * @Descripttion:
 * @version:
 * @Author: luoqinglan
 * @Date: 2020-10-13 10:27:56
 * @LastEditors: luoqinglan
 * @LastEditTime: 2023-04-28 11:54:32
 */
export const urls = "https://mysykc.haoxueche.com/work";
// export const urls="http://139.9.5.149:19002/work";
// export const urls = "http://118.112.181.56:19002/work";
// export const urls = "http://10.50.30.109:19001/work";
// export const urls="http://10.50.82.225:19001/work";
// export const urls="http://10.50.30.109:19001/work"; //测试
