export default {
  //公共搜索
  name: "search",
  props: {
    //是否在多个条件时，展示高级搜索
    mutiple: {
      type: Boolean,
      default: false
    },
    //查询方法
    callFun: {
      type: Function,
      default: null
    },
    //查询条件
    querys: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sel:false
    };
  },
  methods: {
    //点击确定查询
    handleQueryData() {
      this.callFun();
      this.handleCloseSearchContext();
    },
    openSearch(){
        if(!this.sel){
          this.handleOpenSearchContext();
        }else{
          this.handleCloseSearchContext();
        }
    },
    //打开搜索框
    handleOpenSearchContext() {
      let searchBtn = document.querySelector(".search-button");
      searchBtn.className += " open";
      this.sel=true;
    },
    //关闭搜索框
    handleCloseSearchContext() {
      let searchBtn = document.querySelector(".search-button");
      searchBtn.className = "search-button";
      this.sel=false;
    },
    //清空高级查询条件
    handleClear() {
      this.querys.daterange = [];
      this.querys.datesingle = this.$moment();
      if (this.querys.license_daterange) {
        this.querys.license_daterange = [];
      }
      this.querys.list.query = { ...this.getAdvancedQuery };
    },
  }
};
