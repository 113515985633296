import request from "../../utils/request";
// 收费
/**
 * 完善业务中的学员信息
 * @param {object} params
 * @param {string} params.cId     -单位ID 必填
 * @param {string} params.name    -学员姓名 必填
 * @param {string} params.phone   -电话 必填
 */
export function addStu(params) {
  return request("/integration/addStu", {
    method: "post",
    params: params
  });
}

/**
 * 取消单条
 * @param {object} params
 * @param {int} params.cId         -业务ID 必填
 * @param {int} params.unitId      -单位ID 必填
 */
export function cancel(params) {
    return request("/integration/cancel", {
      method: "post",
      params: params
    });
  }

/**
 * 取消业务（组）
 * @param {object} params
 * @param {int} params.orderDate   -预约要培训的日期，如20190301 必填
 * @param {int} params.tags        -业务组标记 必填
 * @param {int} params.unitId      -单位ID 必填
 */
export function cancelTags(params) {
    return request("/integration/cancelTags", {
      method: "post",
      params: params
    });
  }

/**
 * 确认业务
 * @param {object} params
 * @param {int} params.orderDate   -预约要培训的日期，如20190301 必填
 * @param {int} params.tags        -用于确认记录的分组 必填
 * @param {int} params.unitId      -单位ID 必填
 */
export function confirm(params) {
    return request("/integration/confirm", {
      method: "post",
      params: params
    });
  }


/**
 * 获取组号
 * @param {object} params
 */
export function getTags(params) {
    return request("/integration/getTags", {
      method: "get",
      params: params
    });
  }  


/**
 * 获取当日预约情况
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目 必填
 * @param {int} params.unitId       -单位 必填
 * @param {int} params.vehType      -车型 必填
 */
export function getTodayList(params) {
    return request("/integration/getTodayList", {
      method: "get",
      params: params
    });
  }    

/**
 * 询问轮次占用位
 * @param {object} params
 * @param {int} params.orderDate    -预约要培训的日期,如20190301(int)
 * @param {int} params.turnCnt      -轮次(int) 必填
 * @param {int} params.unitId       -单位 必填
 * @param {int} params.vehId        -车辆ID(int) 必填
 */
export function askTurn(params) {
  return request("/integration/askTurn", {
    method: "post",
    params: params
  });
} 



  /**
 * 获取选定日期同车、车型、科目的轮次是否被占用
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目 必填
 * @param {int} params.turnCnt      -轮次 必填
 * @param {int} params.unitId       -单位 必填 
 * @param {int} params.vehId        -车辆 必填
 * @param {int} params.vehType      -车型 必填
 */
export function getTurnCntByVeh(params) {
    return request("/integration/getTurnCntByVeh", {
      method: "get",
      params: params
    });
  }    


  /**
 * 预提交业务
 * @param {object} params
 * @param {int} params.cType        -类型 0 租车 1 VIP赠送 2 优惠券 3 其他 必填
 * @param {int} params.cashType     -付款方式 1 微信 2 支付宝 3 现金 4 POS 必填
 * @param {int} params.costTime     -购买时间（分钟） 必填
 * @param {int} params.enterCode    -上车验证码 必填
 * @param {int} params.netType      -录入类型 0 网上预约 1 微信预约 2 现场预约 必填 
 * @param {int} params.orderDate    -预约要培训的日期，如20190301 必填
 * @param {int} params.personCnt    -上车人数 必填
 * @param {int} params.phone        -电话 必填
 * @param {int} params.shortId      -学校简称关联ID 必填
 * @param {int} params.stage        -科目 必填
 * @param {int} params.status       -状态 0 正常 1 结算 2 异常 3 注销 必填
 * @param {int} params.tags         -用于确认记录的分组 必填
 * @param {int} params.turnCnt      -轮次号 必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.vehId        -车辆行ID 必填
 * @param {int} params.vehType      -车型 必填 
 */
export function preAdd(params) {
    return request("/integration/preAdd", {
      method: "post",
      params: params
    });
  }   
  
  /**
 * 查看优惠券可用性
 * @param {object} params
 * @param {int} params.coupon        优惠券数据(4m) 必填
 * @param {int} params.eId           单位电子标签（32） 必填
 */
export function couponExists(params) {
  return request("/integrationList/couponExists", {
    method: "post",
    params: params
  });
}   


  
//获取培训情况列表


  /**
 * 获取培训情况列表
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.veh         -车牌号 必填
 * @param {int} params.vehType      -车型   必填
 */
export function getTrainList(params) {
    return request("/integrationTrain/getList", {
      method: "get",
      params: params
    });
  }  



 /**
 * 获取培训情况总数
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.veh          -车牌号 必填
 * @param {int} params.vehType      -车型   必填
 */
export function getListCount(params) {
    return request("/integrationTrain/getListCount", {
      method: "get",
      params: params
    });
  }    



//获取计费详情列表

  /**
 * 获取计费情况列表
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.vehType      -车型   必填
 */
export function getIntegrationList(params) {
    return request("/integrationList/getList", {
      method: "get",
      params: params
    });
  }  



 /**
 * 获取培训情况总数
 * @param {object} params
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.vehType      -车型   必填
 */
export function getIntegrationCount(params) {
    return request("/integrationList/getListCount", {
      method: "get",
      params: params
    });
  }  
  
  
 /**
 * 获取培训照片
 * @param {object} params
 * @param {int} params.cId          - 必填
 * @param {int} params.unitId       -单位ID 必填
 */
export function getTrainPhoto(params) {
  return request("/integrationList/getTrainPhoto", {
    method: "get",
    params: params
  });
}  

 /**
 *获取培训照片(通过身份证)
 * @param {object} params
 * @param {int} params.begin      -开始时间 必填
 * @param {int} params.end        -结束时间 必填
 * @param {int} params.certId     -学员身份证 必填
 */
export function getTrainPhotoByStuCertId(params) {
  return request("/integrationList/getTrainPhotoByStuCertId", {
    method: "get",
    params: params
  });
}  


 /**
 * 获取培训轨迹
 * @param {object} params
 * @param {int} params.begin        -开始时间 必填
 * @param {int} params.end          -结束时间 必填
 * @param {int} params.imei         -设备IMEI 必填
 */
export function getTrainTrack(params) {
  return request("/integrationList/getTrainTrack", {
    method: "get",
    params: params
  });
}  







  // 业务审核
  /**
 * 获取申请情况列表
 * @param {object} params
 * @param {int} params.applyDate    -日期起，如20190301 必填
 * @param {int} params.applyDateEnd -日期止，如20190301 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量，如20190301 必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 */
export function getAuditList(params) {
  return request("/integrationAudit/getList", {
    method: "get",
    params: params
  });
}   

  /**
 * 获取申请情况总数
 * @param {object} params
 * @param {int} params.applyDate    -日期起，如20190301 必填
 * @param {int} params.applyDateEnd -日期止，如20190301 必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 */
export function getAuditCount(params) {
  return request("/integrationAudit/getListCount", {
    method: "get",
    params: params
  });
}   

 /**
 * 审核申请
 * @param {object} params
 * @param {int} params.applyDate    -日期起，如20190301 必填
 * @param {int} params.applyDateEnd -日期止，如20190301 必填
 * @param {int} params.cId          -业务ID 必填
 */
export function apply(params) {
  return request("/integrationAudit/apply", {
    method: "post",
    params: params
  });
}  

 /**
 * 审核申请
 * @param {object} params
 * @param {int} params.auditNote    -审核意见 必填
 * @param {int} params.cId          -单位ID 必填
 */
export function deny(params) {
  return request("/integrationAudit/deny", {
    method: "post",
    params: params
  });
}  


 /**
 * 审核申请
 * @param {object} params
 * @param {int} params.auditNote    -审核意见 必填
 * @param {int} params.cId          -单位ID 必填
 */
export function pass(params) {
  return request("/integrationAudit/pass", {
    method: "post",
    params: params
  });
}  


 /**
 * 通过组编号获取计费信息
 * @param {object} params
 * @param {int} params.tags     -组编号 必填
 * @param {int} params.unitId   -单位ID 必填
 */
export function getListByTags(params) {
  return request("/integrationList/getListByTags", {
    method: "get",
    params: params
  });
}


/**
 * 发送验证码
 * @param {object} params
 * @param {int} params.costTime     -购买分钟 必填
 * @param {int} params.enterCode    -验证码   必填
 * @param {int} params.orderDate    -预约日期 必填
 * @param {int} params.phone        -手机号   必填
 * @param {int} params.stage        -科目     必填
 * @param {int} params.turnCnt      -轮次     必填
 * @param {int} params.vehNo       -车号      必填
 * @param {int} params.vehType     -车型      必填
 */
export function sendCode(params) {
    return request("/sms/enterCodeSmsPush", {
        method: "post",
        params: params
    });
}

/**
 * 关联学员列表查询
 * @param {object} params
 * @param {int} params.cId      -业务ID 必填
 */
export function modifyStu(params) {
    return request("/integrationList/getStuByCId", {
        method: "get",
        params: params
    });
}

/**
 * 关联学员列表查询
 * @param {object} params
 * @param {int} params.cId            -业务ID 必填
 * @param {int} params.name           -旧学员姓名 必填
 * @param {int} params.newCertId      -新身份证号 必填
 * @param {int} params.newName        -新学员名字 必填
 * @param {int} params.newPhone       -新电话号码 必填
 * @param {int} params.phone          -旧电话号码 必填
 *
 */
export function stuDataa(params) {
    return request("/integration/modifyStu", {
        method: "post",
        params: params
    });
}

/** 关联学员列表删除
 * @param {object} params
 * @param {int} params.cId            -业务ID 必填
 * @param {int} params.name           -姓名 必填
 * @param {int} params.phone          -电话号码 必填
 */
export function stuDel(params) {
    return request("/integration/delStu", {
        method: "post",
        params: params
    });
}
/**获取照片异常的培训情况列表
 @param   {object} params
 @param   {int}    params.orderDate
 @param   {int}    params.orderDateEnd
 @param   {int}    params.pageIndex
 @param   {int}    params.pageSize
 @param   {int}    params.stage
 @param   {int}    params.stage.unitId
 @param   {string} params.stage.veh
 @param   {string} params.stage.vehType
 */
export function PhotoAbnormalList(params) {
    return request("/integrationTrain/getPhotoAbnormalList",{
        method:"get",
        params:params
    });
}
/**获取培训情况总数
 @param {object}  params
 @param {int}    params.orderDate
 @param {int}    params.orderDateEnd
 @param {int}    params.stage
 @param {int}    params.stage.unitId
 @param {string} params.stage.veh
 @param {string} params.stage.vehType
 */
export function PhotoAbnormalListCount(params) {
    return request("/integrationTrain/getPhotoAbnormalListCount",{
        method:"get",
        params:params
    })
}
/**根据课堂ID获取照片
 @param   {object} params
 @param   {int}    params.orderDate
 @param   {int}    params.orderDateEnd
 @param   {int}    params.stage
 @param   {int}    params.stage.unitId
 @param   {string} params.stage.veh
 @param   {string} params.stage.vehType
 */
export function PhotoByClassId(params) {
    return request("/integrationTrain/getPhotoByClassId",{
        method:"get",
        params:params
    })
}

/**根据根据CID获取照片
 @param   {object} params
 @param   {int}    params.cId
 */
export function getPhotoByCId(params) {
  return request("/integrationTrain/getPhotoByCId",{
      method:"get",
      params:params
  })
}

/**业务人脸对比异常获取照片异常的培训情况列表
 * @param {object} params
 * @param {int} params.begin         -开始日期,日期格式为yyyy-MM-dd HH:mm:ss(30) 必填
 * @param {int} params.end           -结束日期,日期格式为yyyy-MM-dd HH:mm:ss(30)
 * @param {int} params.pageIndex     -页索引 必填
 * @param {int} params.pageSize      -页容量 必填
 * @param {int} params.readTag       -阅读标志 0 未读 1 已读 -1 全部 必填
 */
export function getLocalTrainAbnormalList(params) {
  return request("/integrationTrain/getLocalTrainAbnormalList",{
      method:"get",
      params:params
  })
}

/**业务人脸对比情况总数
 * @param {object} params
 * @param {int} params.begin         -开始日期,日期格式为yyyy-MM-dd HH:mm:ss(30) 必填
 * @param {int} params.end           -结束日期,日期格式为yyyy-MM-dd HH:mm:ss(30)
 * @param {int} params.readTag       -阅读标志 0 未读 1 已读 -1 全部 必填
 */
export function getLocalTrainAbnormalListCount(params) {
  return request("/integrationTrain/getLocalTrainAbnormalListCount",{
      method:"get",
      params:params
  })
}

/**根据classId 将异常的数据处理已读状态
 * @param {object} params
 * @param {int} params.veh   classId
 */
export function setLocalTrainAbnormalReaded(params) {
  return request("/integrationTrain/setLocalTrainAbnormalReaded",{
      method:"post",
      params:params
  })
}
/**根据classId 将异常的数据处理已读状态
 * @param {object} params
 * @param {int} params.begin    开始日期
 * @param {int} params.end      结束日期
 * @param {int} params.readTag  阅读标志 0 未读 1 已读 -1 全部
 * @param {int} params.unitId   单位id
 */
export function getLocalTrainAbnormalListCount2(params) {
  return request("/integrationTrain/getLocalTrainAbnormalListCount2",{
      method:"get",
      params:params
  })
}

/**获取照片异常的学员列表
 * @param {object} params
 * @param {int} params.begin    开始日期
 * @param {int} params.end      结束日期
 * @param {int} params.readTag  阅读标志 0 未读 1 已读 -1 全部
 * @param {int} params.unitId   单位id
 */
export function getLocalTrainAbnormalList2(params) {
  return request("/integrationTrain/getLocalTrainAbnormalList2",{
      method:"get",
      params:params
  })
}

/**根据身份证获取学员培训过程照片
 * @param {object} params
 * @param {int} params.begin    开始日期
 * @param {int} params.certId   身份证
 * @param {int} params.unitId   单位id
 */
export function getPhotoByCertId(params) {
  return request("/integrationTrain/getPhotoByCertId",{
      method:"get",
      params:params
  })
}

/**根据身份证 将异常的数据处理已读状态
 * @param {object} params
 * @param {int} params.begin    开始日期
 * @param {int} params.certId   身份证
 */
export function setLocalTrainAbnormalReadedByCertId(params) {
  return request("/integrationTrain/setLocalTrainAbnormalReadedByCertId",{
      method:"post",
      params:params
  })
}
/**
 *  调配日志
 *  @param{object} params
 *  @param{int} params.cId     业务ID
 */
export function getAllocateLog(params) {
    return request("/monitor/getAllocateLog",{
        method:"get",
        params:params
    })
}

//退费详情

/**
 *  获取退费列表总数
 *  @param{object} params
 *  @param{int} params.begin         开始时间
 *  @param{int} params.end           结束时间
 *  @param{int} params.stageMark     业务编号 0 全部
 *  @param{int} params.status        状态 -1 全部 0 未审 1 通过 2 驳回
 *  @param{int} params.unitId        单位ID 
 */
export function getCount(params) {
  return request("/refund/getCount",{
      method:"get",
      params:params
  })
}

/**
 *  获取退费列表
 *  @param{object} params
 *  @param{int} params.begin         开始时间
 *  @param{int} params.end           结束时间
 *  @param{int} params.pageIndex     页索引
 *  @param{int} params.pageSize      页容量
 *  @param{int} params.stageMark     业务编号 0 全部
 *  @param{int} params.status        状态 -1 全部 0 未审 1 通过 2 驳回
 *  @param{int} params.unitId        单位ID 
 */
export function getRefundList(params) {
  return request("/refund/getList",{
      method:"get",
      params:params
  })
}

/**
 *  获取业务订单
 *  @param{object} params
 *  @param{string} params.eId           驾校编码
 *  @param{int} params.stageMark     业务编号 0 全部
 */
export function getStageMark(params) {
  return request("/refund/getStageMark",{
      method:"get",
      params:params
  })
}


/**
 *  退费申请
 *  @param{object} params
 *  @param{string} params.applyUserId     申请人
 *  @param{string} params.attachMent      附加URL
 *  @param{string} params.client          联系人姓名
 *  @param{int} params.cost               退费费用（分）
 *  @param{string} params.eId             驾校编码
 *  @param{string} params.note            退费原因
 *  @param{string} params.phone           联系电话
 *  @param{int} params.stageMark          业务编号
 */
export function refundApply(params) {
  return request("/refund/refundApply",{
      method:"post",
      params:params
  })
}


/**
 *  退费审核
 *  @param{object} params
 *  @param{string} params.auditUserId     审核人
 *  @param{string} params.eId             驾校编码
 *  @param{int} params.stageMark          业务编号
 *  @param{status} params.status        状态 1 接收 2 驳回
 */
export function refundAudit(params) {
  return request("/refund/audit",{
      method:"post",
      params:params
  })
}


//退费详情

/**
 *  获取退费列表总数
 *  @param{object} params
 *  @param{int} params.begin         开始时间
 *  @param{int} params.end           结束时间
 *  @param{int} params.stageMark     业务编号 0 全部
 *  @param{int} params.status        状态 -1 全部 0 未审 1 通过 2 驳回
 *  @param{int} params.eId           单位标签 
 */
export function getListByEIdCount(params) {
  return request("/refund/getListByEIdCount",{
      method:"get",
      params:params
  })
}

/**
 *  获取退费列表
 *  @param{object} params
 *  @param{int} params.begin         开始时间
 *  @param{int} params.end           结束时间
 *  @param{int} params.pageIndex     页索引
 *  @param{int} params.pageSize      页容量
 *  @param{int} params.stageMark     业务编号 0 全部
 *  @param{int} params.status        状态 -1 全部 0 未审 1 通过 2 驳回
 *  @param{int} params.eId           单位标签 
 */
export function getListByEId(params) {
  return request("/refund/getListByEId",{
      method:"get",
      params:params
  })
}

/**
 *  用户查询上车码二次密码验证
 *  @param{object} params
 *  @param{int} params.psw          密码
 *  @param{int} params.stageMark    订单号码
 *  @param{int} params.unitId       单位ID
 *  @param{int} params.userId       用户ID
 */
export function postValidUser(params) {
    return request("/integrationList/validUser",{
        method:"post",
        params:params
    })
}

/**
 * 获取计费情况总数(通过用户)
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.vehType      -车型   必填
 * @param {int} params.status       -状态   必填
 * @param {int} params.userId       -用户Id   必填
 */
export function getListCountByUserId(params) {
  return request("/integrationList/getListCountByUserId",{
      method:"get",
      params:params
  })
}

/**
 *  获取计费情况列表(通过用户)
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.vehType      -车型   必填
 * @param {int} params.status       -状态   必填
 * @param {int} params.userId       -用户Id   必填
 */
export function getListByUserId(params) {
  return request("/integrationList/getListByUserId",{
      method:"get",
      params:params
  })
}

/**
 *  发票列表总数
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.status       -状态(空 全部  -1未出票 -2已出票)   必填
 * @param {int} params.fax         -是否出票(空 全部  -1未出票 -2已出票)   必填
 */
export function getListCountByFax(params) {
  return request("/fax/getListCountByFax",{
      method:"get",
      params:params
  })
}

/**
 *  发票列表
 * @param {object} params
 * @param {int} params.orderDate    -日期起，如20190301 必填
 * @param {int} params.orderDateEnd -日期止，如20190301 必填
 * @param {int} params.pageIndex    -页索引 必填
 * @param {int} params.pageSize     -页容量 必填
 * @param {int} params.stage        -科目   必填
 * @param {int} params.unitId       -单位ID 必填
 * @param {int} params.stageMark    -流水号 必填
 * @param {int} params.status       -状态(空 全部  -1未出票 -2已出票)   必填
 * @param {int} params.fax         -是否出票(空 全部  -1未出票 -2已出票)   必填
 */
export function getListByFax(params) {
  return request("/fax/getListByFax",{
      method:"get",
      params:params
  })
}


/**
 *  打印发票
 * @param {object} params
 * @param {int} params.email        -电子邮箱  非必填
 * @param {int} params.faxType      -发票种类 必填
 * @param {int} params.userId       -用户Id   必填
 * @param {int} params.stageMark    -业务编号 必填
 * @param {int} params.unitId       -单位ID 必填
 */
export function applyFax(params) {
  return request("/fax/apply",{
      method:"post",
      params:params
  })
}

/**
 *  修改票号
 * @param {object} params
 * @param {int} params.faxNew      -发票种类 必填
 * @param {int} params.userId       -用户Id   必填
 * @param {int} params.stageMark    -业务编号 必填
 * @param {int} params.unitId       -单位ID 必填
 */
export function changeFax(params) {
  return request("/fax/change",{
      method:"post",
      params:params
  })
}

/**
 *  本校教练员汇总
 * @param {object} params
 * @param {int} params.eId              -eId 必填
 * @param {int} params.teaCertId        -教练员Id
 * @param {int} params.data             -日期 必填
 */
export function getLocalTotal(params) {
    return request("/report/getLocalTrainTotal",{
        method:"post",
        params:params
    })
}

/**
 *  本校教练员汇总
 * @param {object} params
 * @param {int} params.eId              -eId 必填
 * @param {int} params.teaCertId        -教练员Id
 * @param {int} params.data             -日期 必填
 */
export function getLocalDetail(params) {
    return request("/report/getLocalTrainDetail",{
        method:"post",
        params:params
    })
}

/**
 *  本校学员验证
 * @param {object} params
 * @param {int} params.certId               -certId  必填
 */
export function getvalidLocalStu(params) {
    return request("/integration/validLocalStu",{
        method:"get",
        params:params
    })
}