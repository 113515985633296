import Vue from "vue";
import App from "./App.vue";
import router from "./utils/router.js";
import store from "./store/index.js";
import Antd from "ant-design-vue";
import VueResource from "vue-resource";
import "ant-design-vue/dist/antd.css";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 引入jq
import $ from "jquery";
//引入moment
import moment from "moment";
import XLSX from "xlsx";
// 引入打印组件
// import Print from 'vue-print-nb'
// //注册打印组件
// Vue.use(Print);
// import { TimePicker } from 'element-ui';
// Vue.use(TimePicker);
// import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$moment = moment;
Vue.prototype.$XLSX = XLSX;
Vue.use(Antd);
Vue.use(VueResource);
Vue.config.productionTip = false;
//引入下拉框选项
import { options } from "@/utils/options";
Vue.prototype.$selector = options;
//返回下拉框选项lable
import { rformat } from "@/utils/rformat";
Vue.prototype.$rformat = rformat;
import request from "./utils/request.js";
Vue.prototype.$request = request;
import { mapActions, mapGetters } from "vuex";
//

// 引入打印组件
import Print from "@/utils/print";
Vue.use(Print); // 注册
//引入枚举值
import { enums } from "@/utils/enums";
Vue.prototype.$enums = enums;
//公共ContentSearch组件
import ContentSearch from "@/components/ContentSearch/index.vue";
Vue.component("ContentSearch", ContentSearch);
//公共ContentHeader组件
import ContentHeader from "@/components/ContentHeader/index.vue";
Vue.component("ContentHeader", ContentHeader);
//公共天气组件
import weather from "@/components/weather/index.vue";
Vue.component("weather", weather);
//编辑框组件
import EditableCell from "@/components/EditableCell/index.vue";
Vue.component("EditableCell", EditableCell);
//打印组件
import PrintTicket from "@/components/printTicket/index.vue";
Vue.component("PrintTicket", PrintTicket);

//导出组件
import exportExcel from "@/components/exportExcel/index.vue";
Vue.component("exportExcel", exportExcel);

//导出收费统计汇总表
import exportTotal from "@/components/exportTotal/index.vue";
Vue.component("exportTotal", exportTotal);
//侧边展示组件
import Drawer from "@/components/drawer/index.vue";
Vue.component("Drawer", Drawer);
//Excel

//侧边展示组件
import Popover from "@/components/Popover/index.vue";
Vue.component("Popover", Popover);
//微信选中组件
import Swcheck from "@/components/Swcheck/index.vue";
Vue.component("Swcheck", Swcheck);

//地图组件
import vueMap from "@/components/vueMap/index.vue";
Vue.component("vueMap", vueMap);

// 车号树形菜单
import treeMenu from "@/components/treeMenu/index.vue";
Vue.component("treeMenu", treeMenu);

// 移动端可拖动原形
import dragerCircle from "@/components/dragerCircle/index.vue";
Vue.component("dragerCircle", dragerCircle);

//柱形图可变图形
import barImg from "@/components/barImg/index.vue";
Vue.component("barImg", barImg);

//原点图形
import dotImg from "@/components/dotImg/index.vue";
Vue.component("dotImg", dotImg);

//报表组件
import reportForm from "@/components/reportForm/index.vue";
Vue.component("reportForm", reportForm);

//报表教练明细组件
import reportFormtea from "@/components/reportFormtea/index.vue";
Vue.component("reportFormtea", reportFormtea);

//报表用车明细
import reportFormCar from "@/components/reportFormCar/index.vue";
Vue.component("reportFormCar", reportFormCar);
// 引入收藏接口
import { addFav } from "@/service/public/index";
//定义mixin混入对象
var mixin = {
  methods: {
    //查询详情数据
    ...mapActions({
      setQueryByAdvanced: "StoreDataQuery/setQueryByAdvanced",
      setQueryByFav: "StoreDataQuery/setQueryByFav",
      setUserRoles: "user/setUserRoles",
    }),
    //路由跳转，取消跳转/编辑后跳转
    handleRouterJump(path, id) {
      if (id) {
        path += id;
      }
      this.$router.replace({
        path: path,
      });
    },
    //统一分页对象
    handleInitPagination(list) {
      // console.log('list',list.total)
      return {
        // 是否可以快速跳转至某页
        showQuickJumper: true,
        // 是否可以改变 pageIndex
        showSizeChanger: true,
        current: list.query.pageIndex,
        pageSize: list.query.pageSize,
        total: list.total,
        // 用于显示数据总量和当前数据顺序
        showTotal: (total) => `共${total}条`,
        pageSizeOptions: ["10", "50", "100"],
      };
    },
    //统一分页对象人车绑定专用
    handleInitPaginations(manCarList) {
      return {
        // 是否可以快速跳转至某页
        // showQuickJumper: true,
        // 是否可以改变 pageIndex
        showSizeChanger: true,
        current: manCarList.select.pageIndex,
        pageSize: manCarList.select.pageSize,
        total: manCarList.total,
        // 用于显示数据总量和当前数据顺序
        showTotal: (total) => `共${total}条`,
        pageSizeOptions: ["100"],
      };
    },
    //统一触发分页查询人车绑定专用
    handlePageChanges(paginations, manCarList, callfunc) {
      manCarList.select.pageSize = paginations.pageSize;
      manCarList.select.pageIndex = paginations.current;
      callfunc("paginations");
    },
    //统一触发分页查询
    handlePageChange(pagination, list, callfunc) {
      list.query.pageSize = pagination.pageSize;
      list.query.pageIndex = pagination.current;
      callfunc("pagination");
    },
    //统一处理表单重置
    handleFormFieldsReset(form) {
      form.resetFields();
    },
    //日期选择后触发
    handleChangeOnRangePicker(date, query, callfunc, dateName) {
      let end = "";
      let begin = "";
      let datesingle = "";
      if (date === null) {
        datesingle = "";
        begin = date[0].format("YYYYMMDD");
        end = date[1].format("YYYYMMDD");
      } else if (date.length) {
        begin = date[0].format("YYYYMMDD");
        end = date[1].format("YYYYMMDD");
      } else if (date) {
        datesingle = date.format("YYYYMMDD");
      }
      if (dateName === "start") {
        query.orderDate = begin;
        query.orderDateEnd = end;
      } else if (dateName === "license") {
        query.licenseBegin = begin;
        query.licenseEnd = end;
      } else if (dateName === "been") {
        query.begin = begin;
        query.end = end;
      } else if (dateName === "date") {
        query.date = datesingle;
      } else if (dateName === "trianDaily") {
        query.date = begin;
        query.dateEnd = end;
      } else {
        query.orderDate = end;
        query.orderDateEnd = begin;
      }
      if (callfunc) {
        callfunc();
      }
    },
    // 快捷入口方法
    addFav(userId, menuName) {
      let params = { menuName: menuName, userId: userId };
      this.userRoles.map((item) => {
        item.children.map((innerItem) => {
          if (menuName === innerItem.path.substr(1)) {
            if (innerItem.fav === 1) {
              this.$message.info("该菜单已经收藏");
              return;
            } else {
              addFav(params).then((res) => {
                if (!res.message) {
                  this.$message.info("快捷入口菜单添加成功");
                  this.setQueryByFav(1);
                  this.setUserRoles();
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          }
        });
      });
    },
    // 判断是否收藏
    isFav() {
      this.userRoles.map((item) => {
        item.children.map((innerItem) => {
          if (innerItem.path === this.$route.path) {
            this.setQueryByFav(innerItem.fav);
          }
        });
      });
    },
    // 时间转换
    transForm(item) {
      return (
        item.substring(0, item.indexOf("T", 0)) +
        " " +
        item.substring(item.indexOf("T", 0) + 1, item.indexOf(".", 0))
      );
    },
    // 公共导出数据方法
    comexport(columns, results, isRank, isup) {
      let titledata = [];
      let excelData = [];
      for (let i in columns) {
        titledata.push(columns[i]["title"]);
      }
      excelData.push(titledata);
      let data = [];
      let n = 1;
      for (let j in results) {
        data = [];
        if (!isRank) {
          data.push(n);
        }
        for (let k in columns) {
          if (columns[k]["dataIndex"] !== undefined) {
            if (columns[k]["dataIndex"] === "trainAdd") {
              data.push(results[j][columns[k]["dataIndex"]]);
            } else if (columns[k]["dataIndex"] === "faxTime") {
              let faxTime = results[j][columns[k]["dataIndex"]]
                ? results[j][columns[k]["dataIndex"]].substr(
                    0,
                    results[j][columns[k]["dataIndex"]].match("T").index
                  )
                : "";
              data.push(faxTime);
            } else if (columns[k]["dataIndex"] === "cType") {
              let cType = this.$enums.cType[
                results[j][columns[k]["dataIndex"]]
              ];
              data.push(cType);
            } else if (
              columns[k]["dataIndex"] === "smallVehStage2" ||
              columns[k]["dataIndex"] === "bigVehStage2" ||
              columns[k]["dataIndex"] === "smallVehStage3" ||
              columns[k]["dataIndex"] === "bigVehStage3"
            ) {
              data.push(results[j][columns[k]["dataIndex"]] / 60);
            } else if (columns[k]["dataIndex"] === "couponPrice") {
              let couponPrice = results[j][columns[k]["dataIndex"]]
                ? results[j][columns[k]["dataIndex"]] / 100
                : 0;
              data.push(couponPrice);
            } else if (columns[k]["dataIndex"] === "localTags") {
              let canAny = results[j][columns[k]["dataIndex"]]
                ? "本校"
                : "非本校";
              data.push(canAny);
            } else if (columns[k]["dataIndex"] === "canAny") {
              let canAny = results[j][columns[k]["dataIndex"]] ? "是" : "否";
              data.push(canAny);
            } else if (columns[k]["dataIndex"] === "minutesTotal") {
              let minutesTotal = parseInt(
                results[j][columns[k]["dataIndex"]] / 60
              );
              data.push(
                `${parseInt(
                  results[j][columns[k]["dataIndex"]]
                )}分钟(${minutesTotal}小时)`
              );
            } else if (columns[k]["dataIndex"] === "costType") {
              let status = this.$enums.costType[
                results[j][columns[k]["dataIndex"]]
              ];
              data.push(status);
            } else if (columns[k]["dataIndex"] === "status") {
              let status = this.$enums.stageStatus[
                results[j][columns[k]["dataIndex"]]
              ];
              data.push(status);
            } else if (columns[k]["dataIndex"] === "auditStatus") {
              let auditStatus = this.$enums.auditStatus[
                results[j][columns[k]["dataIndex"]]
              ];
              data.push(auditStatus);
            } else if (columns[k]["dataIndex"] === "trainTime") {
              let trainTime = parseInt(results[j][columns[k]["dataIndex"]]);
              data.push(trainTime);
            } else if (columns[k]["dataIndex"] === "useStatus") {
              let useStatus =
                results[j][columns[k]["dataIndex"]] == 0 ? "未使用" : "";
              data.push(useStatus);
            } else if (columns[k]["dataIndex"] === "isSch") {
              let isSch = results[j][columns[k]["dataIndex"]]
                ? "本校"
                : "非本校";
              data.push(isSch);
            } else if (
              results[j][columns[k]["dataIndex"]] === undefined ||
              results[j][columns[k]["dataIndex"]] == null ||
              results[j][columns[k]["dataIndex"]] == 0
            ) {
              if (isup == "no") {
                if (results[j][columns[k]["dataIndex"]] == 0) {
                  data.push(results[j][columns[k]["dataIndex"]]);
                } else {
                  data.push("暂无");
                }
              } else {
                data.push("暂无");
              }
            } else {
              if (
                columns[k]["dataIndex"] === "cost" ||
                columns[k]["dataIndex"] === "teaCommision" ||
                columns[k]["dataIndex"] === "vipPrice" ||
                columns[k]["dataIndex"] === "groupPrice" ||
                columns[k]["dataIndex"] === "price"
              ) {
                data.push(results[j][columns[k]["dataIndex"]] / 100);
              } else if (
                columns[k]["dataIndex"] === "costtime" ||
                columns[k]["dataIndex"] === "costTime" ||
                columns[k]["dataIndex"] === "smallVehStage2" ||
                columns[k]["dataIndex"] === "bigVehStage2" ||
                columns[k]["dataIndex"] === "smallVehStage3" ||
                columns[k]["dataIndex"] === "bigVehStage3"
              ) {
                data.push(results[j][columns[k]["dataIndex"]] / 60);
              } else if (
                columns[k]["dataIndex"] === "trainBegin" ||
                columns[k]["dataIndex"] === "trainEnd"
              ) {
                if (typeof results[j][columns[k]["dataIndex"]] == "number") {
                  var unixTimestamp = new Date(
                    results[j][columns[k]["dataIndex"]] * 1000
                  );
                  let commonTime = unixTimestamp.toLocaleString("chinese", {
                    hour12: false,
                  }); //转换为24小时制的时间格式
                  data.push(commonTime);
                } else {
                  data.push(results[j][columns[k]["dataIndex"]]);
                }
              } else if (columns[k]["dataIndex"] === "faxType") {
                let faxType =
                  results[j][columns[k]["dataIndex"]] == "c"
                    ? "普票"
                    : results[j][columns[k]["dataIndex"]] == "t"
                    ? "电子发票"
                    : "暂无";
                data.push(faxType);
              } else if (columns[k]["dataIndex"] === "cashType") {
                let cashType =
                  results[j][columns[k]["dataIndex"]] == 1
                    ? "微信"
                    : results[j][columns[k]["dataIndex"]] == 2
                    ? "支付宝"
                    : results[j][columns[k]["dataIndex"]] == 3
                    ? "现金"
                    : results[j][columns[k]["dataIndex"]] == 4
                    ? "转账"
                    : "信用卡";
                data.push(cashType);
              } else if (columns[k]["dataIndex"] === "inTime") {
                let inTime = this.transForm(
                  results[j][columns[k]["dataIndex"]]
                );
                data.push(inTime);
              } else {
                data.push(results[j][columns[k]["dataIndex"]]);
              }
            }
          }
        }
        n++;
        excelData.push(data);
      }
      return excelData;
    },

    //去除操作
    exportDate(excelsource) {
      excelsource = excelsource.map((item) => {
        if (item[0] === "序号") {
          return item.slice(0, item.length - 1);
        } else {
          return item;
        }
      });
      return excelsource;
    },
    //去除操作
    exportDate1(excelsource) {
      let isdel = false;
      excelsource = excelsource.map((item) => {
        if (item[0] === "序号") {
          isdel = true;
          return item.slice(1, item.length);
        } else {
          if (isdel) {
            item = item.slice(1, item.length);
          }
          return item;
        }
      });
      console;
      return excelsource;
    },
    // 转换unix 时间
    translateDate(item) {
      if (!item) {
        return "暂无";
      }
      var unixTimestamp = new Date(item * 1000);
      let commonTime = unixTimestamp.toLocaleString("chinese", {
        hour12: false,
      }); //转换为24小时制的时间格式
      return commonTime;
    },
    getBtnRole(params) {
      let menusDate = JSON.parse(sessionStorage.getItem("menus"));
      let pId = menusDate.filter((item) => {
        return item.menuName == params;
      })[0].menuId;
      let btnRole = menusDate.filter((item) => {
        return item.parentId == pId;
      });
      let res = btnRole.map((item) => {
        return item.menuName;
      });
      return res;
    },
  },
  computed: {
    ...mapGetters({
      getAdvancedQuery: "getAdvancedQuery",
      getFav: "getFav",
      userRoles: "userRoles",
    }),
  },
};
//全局注入mixin
Vue.mixin(mixin);
let loadfrist = true;

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  let userId = JSON.parse(sessionStorage.getItem("userId"));
  // 判断是否由微信访问预约单
  if (to.path === "wxOrder") {
    next();
  }
  if (!userId && to.path !== "/login") {
    if (
      to.path === "/refDetail" ||
      to.path === "/detail" ||
      to.path == "/reportForm"
    ) {
      next();
    } else {
      next({ path: "/login" });
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      store.dispatch("user/setcurrentMenu", to.path);
      if (loadfrist) {
        if (store.getters.userRoles.length === 0) {
          store
            .dispatch("user/setUserRoles")
            .then(() => {
              let menudata = store.getters.userRoles;
              console.log("权限列表", menudata);
              for (let i in menudata) {
                router.options.routes.push(menudata[i]);
              }
              router.addRoutes(router.options.routes); // 动态添加可访问路由表
              next({ ...to, replace: true });
            })
            .catch((err) => {
              store.dispatch("user/loginOut");
            });
        }
        loadfrist = false;
      } else {
        next();
      }
    }
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
