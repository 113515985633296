export default {
    //公共搜索
    name: "exportExcel",
    props: {
        //是否为统计
        statistics: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            data: [],
            excelName: [],
            selName: [],//查询条件名称
            excelData: [],//导出数据
        }
    },
    methods: {
        exportExcel() {
            this.$emit('isExport');
        },
        getExport(sel, excel, data) {
            this.excelName = excel;
            this.selName = sel;
            this.excelData = data;
            this.data = [];
            for (let i in this.excelData[0]) {
                this.excelName.push(" ");
            }
            this.data.push(this.excelName);
            this.data.push(this.selName);
            for (let i in this.excelData) {
                this.data.push(this.excelData[i]);
            }
            let aoa = this.data;
            let sheet = XLSX1.utils.aoa_to_sheet(aoa);
            const borderAll = {  //单元格外侧框线
                top: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
            sheet["A1"].s = {
                font: {
                    sz: 12,
                    bold: true
                },
                alignment: {
                    horizontal: "center",
                    vertical: "center",
                    wrap_text: true
                },
            };
            let cellwidth = [];
            for (let i in this.excelData[0]) {
                let r = this.getCharCol(i);
                for (let j = 1; j <= this.data.length; j++) {
                    if (j === 1) { //设置第一行样式
                        sheet[r + j].s = {
                            alignment: {
                                horizontal: "center",
                                vertical: "center",
                                wrap_text: true
                            },
                        };
                    }
                    else if (j === 2) { //设置第一行样式
                        sheet[r + j].s = {
                            border: borderAll,
                            font: {
                                sz: 28,
                                bold: true
                            },
                            alignment: {
                                horizontal: "center",
                                vertical: "center",
                                wrap_text: true
                            },
                        };
                    } else if (j === this.data.length) { //最后一行设置红色
                        if (this.statistics) {
                            sheet[r + j].s = {
                                font: { bold: true, color: { rgb: "FF0000" } }
                            };
                        } else {
                            sheet[r + j].s = { //设置每个单元格样式
                                border: borderAll,
                                alignment: {
                                    horizontal: "center",
                                    vertical: "center",
                                    wrap_text: true
                                },
                            };
                        }
                    } else {
                        sheet[r + j].s = { //设置每个单元格样式
                            border: borderAll,
                            alignment: {
                                horizontal: "center",
                                vertical: "center",
                                wrap_text: true
                            },
                        };
                    }
                }
                cellwidth.push({
                    wpx: 200
                })
            };
            for (let i in this.selName) { //设置第二行样式
                let r = this.getCharCol(i);
                sheet[r + 2].s = {
                    border: borderAll,
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrap_text: true
                    },
                    fill: { fgColor: { rgb: "B4EEB4" } }
                };
            }
            sheet["!cols"] = cellwidth;
            sheet['!merges'] = [
                // 设置A1-C1的单元格合并
                { s: { r: 0, c: 0 }, e: { r: 0, c: this.excelData[0].length - 1 } }
            ];
            this.openDownloadDialog(this.sheet2blob(sheet), this.excelName[0] + '.xlsx');
        },
        // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
        sheet2blob(sheet, sheetName) {
            sheetName = sheetName || 'sheet1';
            var workbook = {
                SheetNames: [sheetName],
                Sheets: {}
            };
            workbook.Sheets[sheetName] = sheet;
            // 生成excel的配置项
            var wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            };
            var wbout = XLSX.write(workbook, wopts);
            var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

            // 字符串转ArrayBuffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            return blob;
        },
        /**
         * 通用的打开下载对话框方法，没有测试过具体兼容性
         * @param url 下载地址，也可以是一个blob对象，必选
         * @param saveName 保存文件名，可选
         */
        openDownloadDialog(url, saveName) {
            if (typeof url === 'object' && url instanceof Blob) {
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if (window.MouseEvent) event = new MouseEvent('click');
            else {
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        },
        getCharCol(n) {
            let s = '', m = 0;
            while (n > 0) {
                m = n % 26 + 1
                s = String.fromCharCode(m + 64) + s;
                n = (n - m) / 26
            };
            if (s === "") {
                s = "A"
            }
            return s;
        }
    },
    mounted() {
    }
};