import request from "../../utils/request";
// 星级费用设置

/**
 * 星级列表
 * @param {object} params
 * @param {string} params.unitId  -单位ID 必填
 */
export function getStarList(params) {
  return request("/star/getList", {
    method: "get",
    params: params
  });
}

/**
 * 新增星级费用
 * @param {object} params
 * @param {int}    params.price   费用(用分表示)(int) 必填
 * @param {string} params.starName 星级名称 必填
 * @param {int}    params.starType 星级服务类型 1 车辆 2 安全员(int)
 * @param {int}    params.status   状态 0 正常 1 注销(int)
 * @param {string} params.unitId  -单位ID 必填
 */
export function addStar(params) {
    return request("/star/add", {
      method: "post",
      params: params
    });
  }

/**
 * 修改星级费用
 * @param {object} params
 * @param {int}    params.price   费用(用分表示)(int) 必填
 * @param {string} params.starName 星级名称 必填
 * @param {int}    params.starType 星级服务类型 1 车辆 2 安全员(int)
 * @param {int}    params.status   状态 0 正常 1 注销(int)
 * @param {int} params.unitId  -单位ID 必填
 */
export function modifyStar(params) {
    return request("/star/modify", {
      method: "post",
      params: params
    });
  }

/**
 *删除星级费用
 * @param {object} params
 * @param {int}    params.starId   星级ID必填
 * @param {int}    params.unitId  -单位ID 必填
 */
export function delStar(params) {
    return request("/star/del", {
      method: "post",
      params: params
    });
  }

  // 训练费用设置

  /**
 * 训练费用列表
 * @param {object} params
 * @param {string} params.unitId  -单位ID 必填
 */
export function getCostList(params) {
    return request("/costSet/getList", {
      method: "get",
      params: params
    });
  }


  /**
 * 新增训练费用
 * @param {object} params
 * @param {int}    params.costType     费用类型 0 租车 1 自带车 必填
 * @param {int}    params.isSch        是否本校 0 本校 1 非本校 必填
 * @param {int}    params.price        费用(用分表示)必填
 * @param {int}    params.stage        科目 必填
 * @param {int}    params.teaCommision 教练提成（用分表示） 必填
 * @param {int}    params.unitId       单位ID 必填 
 * @param {int}    params.vehType      车型 必填 
 * @param {int}    params.vipPrice     VIP折扣价格（用分表示）必填 
 */
export function addCost(params) {
    return request("/costSet/add", {
      method: "post",
      params: params
    });
  }


/**
 *删除训练费用
 * @param {object} params
 * @param {int}    params.costId       费用ID 必填
 * @param {int}    params.unitId       单位ID 必填 
 */
export function delCost(params) {
    return request("/costSet/del", {
      method: "post",
      params: params
    });
  }  


  /**
 * 修改训练费用
 * @param {object} params
 * @param {int}    params.costId       费用ID 必填
 * @param {int}    params.costType     费用类型 0 租车 1 自带车 必填
 * @param {int}    params.isSch        是否本校 0 本校 1 非本校 必填
 * @param {int}    params.price        费用(用分表示)必填
 * @param {int}    params.stage        科目 必填
 * @param {int}    params.teaCommision 教练提成（用分表示） 必填
 * @param {int}    params.unitId       单位ID 必填 
 * @param {int}    params.vehType      车型 必填 
 * @param {int}    params.vipPrice     VIP折扣价格（用分表示）必填 
 */
export function modifyCost(params) {
    return request("/costSet/modify", {
      method: "post",
      params: params
    });
  }  