import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/homePage",
    },
    {
      path: "/Home",
      name: "基本页面",
      leaf: false,
      component: (resolve) => require(["./../views/Home.vue"], resolve),
      children: [
        {
          path: "/homePage",
          //   meta: { keepAlive: true},
          name: "总览",
          component: (resolve) =>
            require(["./../views/basePage/homePage/index.vue"], resolve),
        },
        {
          path: "/shortcutEntry",
          name: "快捷入口",
          component: (resolve) =>
            require(["../views/basePage/shortcutEntry/index.vue"], resolve),
        },
      ],
    },
    {
      path: "/login",
      component: (resolve) => require(["../views/login/index.vue"], resolve),
    },
    {
      path: "/phoneVehicleStatus",
      component: (resolve) =>
        require([
          "../views/mobilePhonePage/phoneVehicleStatus/index.vue",
        ], resolve),
    },
    {
      path: "/phoneVipManagement",
      component: (resolve) =>
        require([
          "../views/mobilePhonePage/phoneVipManagement/index.vue",
        ], resolve),
    },
    {
      path: "/overView",
      component: (resolve) =>
        require(["../views/mobilePhonePage/overView/index.vue"], resolve),
    },
    {
      path: `/refDetail`,
      component: (resolve) =>
        require(["./../views/refDetail/index.vue"], resolve),
    },
    {
      path: "/detail",
      component: (resolve) =>
        require(["./../views/refDetail/detail/index.vue"], resolve),
    },
    {
      path: "/reportForm",
      component: (resolve) =>
        require(["./../views/feeStatistics/reportForm/index.vue"], resolve),
    },
    {
      path: "/wxOrder",
      component: (resolve) => require(["./../views/wxOrder.vue"], resolve),
    },
    // {
    //     path: '/localSchoolTraining',
    //     component: resolve => require(['./../views/businessData/localSchoolTraining/index.vue'], resolve),
    // },
    // {
    //     path: '/verificationCode',
    //     component: resolve => require(['./../views/businessData/verificationCode/index.vue'], resolve),
    // },
  ],
});
