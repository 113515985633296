import {
  getInfo
} from "@/service/systemSetup/index";
import {
  getStarList,
} from "@/service/costSet/index";
import {
  getVipCount
} from "@/service/resManagement/index";
import {getLocalTrainAbnormalListCount2 } from "@/service/charge/index";
const state = {
  queryByAdvanced: null, //记录表格的高级查询条件
  driverSchool:[], //驾校列表
  starCost:[],//星级价格列表
  starClass:[],
  newsNum:0,
  AbnormalNum:0,
  fav:0
};

const mutations = {
  SETADVANCEQUERY: (state, payload) => {
    state.queryByAdvanced = { ...payload };
  },
  SETDRIVERQUERY: (state, data) => {
    state.driverSchool =data;
  },
  SETSTARCOSTQUERY: (state, data) => {
    state.starCost =data;
  },
  SETNEWSQUERY: (state, data) => {
    state.newsNum =data;
  },
  SETABNORMALQUERY: (state, data) => {
    state.AbnormalNum =data;
  },
  SETFAVSQUERY: (state, data) => {
    state.fav =data;
  }
};

const actions = {
  //设置记录表格的高级查询条件
  setQueryByAdvanced({ commit }, querys) {
    commit("SETADVANCEQUERY", querys);
  },
  setQueryByFav({ commit }, fav) {
    commit("SETFAVSQUERY", fav);
  },
  async setQueryBySchool({ commit }, querys){
    try {
      const res = await getInfo(querys)
      commit("SETDRIVERQUERY", res.data);
      sessionStorage.setItem('driverSchool',JSON.stringify(res.data));
      return res
    } catch (error) {
      throw error
    }
  },
  async setQueryByStarCost({ commit }, querys){
    try {
      const res = await getStarList(querys)
      commit("SETSTARCOSTQUERY", res.data);
      sessionStorage.setItem('starCost',JSON.stringify(res.data));
      return res
    } catch (error) {
      throw error
    }    
  },
  async setQueryByNews({ commit }, querys){
    try {
      const res = await getVipCount(querys);
      if(res.data){
        commit("SETNEWSQUERY", res.data.rowCount);
      }
      return res
    } catch (error) {
      throw error
    }    
  },
  async setQueryByAbnormalList({ commit }, querys){
    try {
      const res = await getLocalTrainAbnormalListCount2(querys);
      if(res.data){
        commit("SETABNORMALQUERY", res.data.cnt);
      }
      return res
    } catch (error) {
      throw error
    }    
  }

};

var asyncDataQuery = {
  namespaced: true, // 这个东西好麻烦
  state,
  mutations,
  actions
};

export default asyncDataQuery;
