/*
 * @Descripttion:
 * @version:
 * @Author: luoqinglan
 * @Date: 2020-10-13 10:27:56
 * @LastEditors: luoqinglan
 * @LastEditTime: 2023-01-06 09:40:07
 */
export const urls="https://mysykc.haoxueche.com/work"; //正式
// export const urls = "http://118.112.181.56:19006/work";
// export const urls="http://139.9.5.149:19002/work";
// export const urls = "http://10.50.30.112:19001/work"; //测试
