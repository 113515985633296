import request from "../../utils/request";
/**
 * 驾校列表
 * @param {array} params
 * @param {int} params.unitId	驾校ID
 * @param {int} params.currentPage - 当前页 非必填
 * @param {int} params.pageSize - 每页记录数 非必填
 */
export function getInfo(params) {
  return request(`/sch/getInfo`, {
    method: "get",
    params: params
  });
}


/**
 * 删除驾校信息
 * @param {array} params
 * @param {int} params.unitId	驾校ID
 * @param {int} params.currentPage - 当前页 非必填
 * @param {int} params.pageSize - 每页记录数 非必填
 */
export function delInfo(params) {
  return request(`/sch/delInfo`, {
    method: "post",
    params: params
  });
}


/**
 * 添加驾校信息
 * @param {array} params
 * @param {int} params.unitId	驾校ID
 * @param {int} params.currentPage - 当前页 非必填
 * @param {int} params.pageSize - 每页记录数 非必填
 */
export function addInfo(params) {
  return request(`/sch/addInfo`, {
    method: "post",
    params: params
  });
}

// 优惠券页面

  /**
* 优惠券总数
 * @param {object} params
 * @param {int} params.eId           单位电子标签（32） 必填
 */
export function getCouponCount(params) {
  return request("/integrationList/getCouponCount", {
    method: "post",
    params: params
  });
} 

 /**
* 优惠券查询（分页用）
 * @param {object} params
 * @param {int} params.eId           单位电子标签（32） 必填
 * @param {int} params.pageIndex     页索引(int) 必填
 * @param {int} params.pageSize      页数量(int) 必填
 */
export function getCouponList(params) {
  return request("/integrationList/getCouponList", {
    method: "post",
    params: params
  });
} 

/**
 *  优惠券作废
 * @param {object} params
 * @param {int} params.eId        单位电子标签（32） 必填
 * @param {int} params.coupon     优惠券数据(4m) 必填
 */
export function delCounpon(params) {
  return request("/integrationList/delCounpon", {
    method: "post",
    params: params
  });
} 

/**
 *  写入优惠券
 * @param {object} params
 * @param {int} params.eId        单位电子标签（32）必填
 * @param {int} params.coupons    优惠券数组，如{’coupons’:[‘1’,’2’,’3’]}(4m) 必填
 * @param {int} params.userId     用户ID(18) 必填
 */
export function couponInsBatch(params) {
  return request("/integrationList/couponInsBatch", {
    method: "post",
    params: params
  });
} 