//导出vue实例
import Vue from "vue";
export const vm = new Vue();

//导出接口地址
export function getUrls() {
  let node_env = process.env.NODE_ENV;
  let vue_app_title = process.env.VUE_APP_TITLE;
  let env =
    node_env === "development"
      ? "development"
      : vue_app_title === "test"
      ? "test"
      : "production";

  let config = require(`./config/${env}.js`);
  return config.urls;
}
//通过浏览器打开并下载文件
export const openDownLoad = link => {
  var new_window = window.open("about:blank", "_blank");
  new_window.location.href = link;
  new_window.onload = function() {
    new_window.close();
  };
};



