
export default {
    //公共搜索
    name: "exportTotal",
    props: {
        //表头
         columns: {
            type: Array,
            default:[]
        },

    },
    data() {
        return {
            data: [],
            excelName:['驾培业务短训业务收费统计汇总表'],
            excelTime:['时间:'],
            excelData:[],//导出数据
            contactKey:[],
            contactName:[],
            firColName:[],
        }
    },
    methods: {
        exportExcel(){
            this.$emit('isExport');
        },
        getContact(val, strTime, fontSize){
            this.contactKey = [];
            this.contactName = [];
            this.transEntry(this.columns);
            let selRow = ['统计范围', strTime, ' ', '', ' ', ' ', ' ', ' ', ' ', ' ' ,'制表人：', ' ', ' ', ' '];
            let retVal = this.getVal(val);
            let firCol = this.getFirColName(this.columns);
            let lastrow = ['业务部核对：', ' ', ' ', '会计核对：', ' ', ' ', ' ', ' ', ' ', ' ' ,'制表人：', ' ', ' ', ' ']
                retVal.unshift(firCol,this.contactName);
                retVal.push(lastrow);
                this.getExport(retVal, selRow, fontSize);
        },
        // 得到第一行数据
        getFirColName(columns){
          let ret = [];
          for(let i = 0; i < columns.length; i++){
               if(columns[i].children){
                 for(let j = 0; j < columns[i].children.length; j++){
                    ret.push(columns[i].title);
                 }

               }else{
                  ret.push(columns[i].title);
               }
          }
          return ret;
        },
        // 获取标题和标题key
        transEntry(entry) {
            for(let i = 0; i< entry.length; i++){
                if (entry[i].children) {
                    this.transEntry(entry[i].children);
                } else {
                     this.contactKey.push(entry[i].dataIndex);
                     this.contactName.push(entry[i].title)
                }
              }
        },
        // 获取表值
        getVal(val){
            let ret = []
            for(let i = 0; i< val.length; i++){
                let item = [];
                for(let j = 0; j < this.contactKey.length; j++){
                    if(!val[i][this.contactKey[j]]){
                        item.push('');
                    }else{
                        item.push(val[i][this.contactKey[j]])
                    }
                }
                ret.push(item);
              }
              return ret;
        },
        getExport(val, selRow, fontSize){
            this.excelData = val;
            this.data = [];
            this.excelName = ['驾培业务短训业务收费统计汇总表'];
            this.excelTime = ['时间:'];
            for (let i in this.excelData[0]) {
                this.excelName.push(" ");
                this.excelTime.push(" ");
            }
            this.data.push(selRow);
            this.data.push(this.excelName);
            this.data.push(this.excelTime);
            for (let i in this.excelData) {
                this.data.push(this.excelData[i]);
            }
            let aoa = this.data;
            let sheet = XLSX1.utils.aoa_to_sheet(aoa);
            const borderAll = {  //单元格外侧框线
                top: {
                    style: 'thin'
                },
                bottom: {
                    style: 'thin'
                },
                left: {
                    style: 'thin'
                },
                right: {
                    style: 'thin'
                }
            };
            sheet["A1"].s = {
                font: {
                    name: '微软雅黑',
                    bold: true
                },
                alignment: {
                    horizontal: "center",
                    vertical: "center",
                    wrap_text: true
                },
            };
            let cellwidth = [];
            for (let i in this.excelData[0]) {
                 let r = this.getCharCol(i);
                for (let j = 1; j <= this.data.length; j++) {
                    if(j===1){ //设置第一行样式
                        sheet[r + j].s = { 
                            border: borderAll,
                            alignment: {
                                horizontal: "left",
                                vertical: "center",
                                wrap_text: true
                            },
                            font: {
                                sz: fontSize,
                                name: '微软雅黑',
                            },
                        };
                    } 
                    else if(j===2){ //设置第二行样式
                        sheet[r + j].s = { 
                            border: borderAll,
                            font: {
                                sz: 24
                                ,
                                bold: false,
                                name: '微软雅黑',
                            },
                            alignment: {
                                horizontal: "center",
                                vertical: "center",
                                wrap_text: true
                            },
                        };
                    } else {
                        sheet[r + j].s = { //设置每个单元格样式
                            border: borderAll,
                            alignment: {
                                horizontal: "center",
                                vertical: "center",
                                wrap_text: true
                            },
                            font: {
                                sz: fontSize,
                                name: '微软雅黑',
                            },
                        };
                    }
                }
                cellwidth.push({
                    wpx: 100
                })
            };
            sheet["!cols"] = cellwidth;
            sheet['!merges'] = [
                // 选择行
                {s: {r: 0, c: 1}, e: {r: 0, c: this.excelData[0].length - 1}},

                // 设置前面两行合并居中
                {s: {r: 1, c: 0}, e: {r: 1, c: this.excelData[0].length - 1}},
                {s: {r: 2, c: 0}, e: {r: 2, c: this.excelData[0].length - 1}},
                // 设置表头
                
                // 设置A1-B1的单元格合并
                {s: {r: 3, c: 0}, e: {r:4, c: 0}},
                {s: {r: 3, c: 1}, e: {r:3, c: 2}},
                {s: {r: 3, c: 3}, e: {r:3, c: 4}},
                {s: {r: 3, c: 5}, e: {r:3, c: 6}},
                {s: {r: 3, c: 7}, e: {r:3, c: 8}},
                {s: {r: 3, c: 9}, e: {r:3, c: 10}},
                {s: {r: 3, c: 11}, e: {r:3, c: 12}},
                {s: {r: 3, c: 13}, e: {r:4, c: 13}}
            ];
            this.openDownloadDialog(this.sheet2blob(sheet), this.excelName[0]+'.xlsx');
        },
        // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
        sheet2blob(sheet, sheetName) {
            sheetName = sheetName || 'sheet1';
            var workbook = {
                SheetNames: [sheetName],
                Sheets: {}
            };
            workbook.Sheets[sheetName] = sheet;
            // 生成excel的配置项
            var wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            };
            var wbout = XLSX.write(workbook, wopts);
            var blob = new Blob([s2ab(wbout)], {type: "application/octet-stream"});

            // 字符串转ArrayBuffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            return blob;
        },
        /**
         * 通用的打开下载对话框方法，没有测试过具体兼容性
         * @param url 下载地址，也可以是一个blob对象，必选
         * @param saveName 保存文件名，可选
         */
        openDownloadDialog(url, saveName) {
            if (typeof url === 'object' && url instanceof Blob) {
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if (window.MouseEvent) event = new MouseEvent('click');
            else {
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        },
        getCharCol(n) {
            let s = '',m = 0;
            while (n > 0) {
                m = n % 26 + 1
                s = String.fromCharCode(m + 64) +s;
                n = (n - m) / 26
            };
            if(s===""){
                s="A"
            }
            return s;
        }

    },
    mounted() {
    }
};