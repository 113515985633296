//下拉框选项值
export const options = {
    // 科目
    projectItem: [
        {
            value: 0,
            label: '全部'
        },
        {
            value: 2,
            label: '科目2'
        }, {
            value: 3,
            label: '科目3'
        }],
    stage: [
        {
            value: 0,
            label: '全部'
        },
        {
            value: 2,
            label: '科目2'
        }, {
            value: 3,
            label: '科目3'
        }],
    // 月报表专用科目
    projectItemy: [
        {
            value: 2,
            label: '科目2'
        }, {
            value: 3,
            label: '科目3'
        }],
    // 车型
    driverCarType: [{
        label: 'A1',
        value: 'A1'
    }, {
        value: 'A2',
        label: 'A2'
    },
    {
        value: 'A3',
        label: 'A3'
    },
    {
        value: 'B1',
        label: 'B1'
    },
    {
        value: 'B2',
        label: 'B2'
    },

    {
        value: 'C1',
        label: 'C1'
    }, {
        value: 'C2',
        label: 'C2'
    },
    {
        value: 'C3',
        label: 'C3'
    },
    {
        value: 'C4',
        label: 'C4'
    }, {
        value: 'C5',
        label: 'C5'
    }, {
        value: '',
        label: '全部'
    }],
    // 车型
    CarType: [{
        label: 'A1',
        value: 'A1'
    }, {
        value: 'A2',
        label: 'A2'
    },
    {
        value: 'A3',
        label: 'A3'
    },
    {
        value: 'B1',
        label: 'B1'
    },
    {
        value: 'B2',
        label: 'B2'
    },

    {
        value: 'C1',
        label: 'C1'
    }, {
        value: 'C2',
        label: 'C2'
    },
    {
        value: 'C3',
        label: 'C3'
    },
    {
        value: 'C4',
        label: 'C4'
    }, {
        value: 'C5',
        label: 'C5'
    }, {
        value: '大车',
        label: '大车'
    }, {
        value: '小车',
        label: '小车'
    }, {
        value: '',
        label: '全部'
    }],
    // 客户类型
    customerType: [{
        value: "personal",
        label: "个人客户"
    },
    {
        value: "publics",
        label: "对公客户"
    }
    ],
    // 审核状态
    checkState: [{
        value: "un_check",
        label: "待审核"
    },
    {
        value: "reject",
        label: "驳回"
    },
    {
        value: "pass",
        label: "通过"
    }
    ],
    // 业务状态
    BusinessStatus: [{
        value: "normal",
        label: "正常"
    }, {
        value: "settled",
        label: "已结算"
    }, {
        value: "cancelled",
        label: "已取消"
    }, {
        value: "abnormal",
        label: "异常"
    }],
    // 异常申请
    abnormalApplication: [
        {
            value: 0,
            label: "训练未完成"
        }, {
            value: 1,
            label: "未训练"
        }, {
            value: 2,
            label: "硬件故障"
        }, {
            value: 3,
            label: "订单错误"
        }, {
            value: 4,
            label: "其他"
        }],
    // 星级名称
    starClass: [{
        value: 0,
        label: "无星"
    }, {
        value: -1,
        label: "全部"
    }, {
        value: 1,
        label: "一星"
    }, {
        value: 2,
        label: "二星"
    }, {
        value: 3,
        label: "三星"
    }, {
        value: 4,
        label: "四星"
    }, {
        value: 5,
        label: "五星"
    }],
    // 星级类型
    starType: [
        {
            value: 1,
            label: "车辆"
        }, {
            value: 2,
            label: "安全员"
        }],
    // 星级状态
    starStatus: [{
        value: 0,
        label: "正常"
    }, {
        value: 1,
        label: "注销"
    }],
    // 考试车状态
    examinationCarStatus: [{
        value: 0,
        label: "正常"
    }, {
        value: 1,
        label: "注销"
    }],
    // 车型
    vehicleType: [{
        value: "cart",
        label: "大车"
    }, {
        value: "trolley",
        label: "小车"
    }],
    // 业务数据类型
    businessDataType: [{
        value: "ticketFlow",
        label: "小票流水报表"
    }],
    // 排序
    chargeSort: [
        {
            value: "orderSrc",
            label: "按预约量升序"
        }, {
            value: "orderDesc",
            label: "按预约量降序"
        },
        {
            value: "vihicleSrc",
            label: "按车号升序"
        }, {
            value: "vihicleDesc",
            label: "按车号降序"
        }
    ],
    // 训练场地
    trainingGround: [{
        value: "wuheyi",
        label: "五合一"
    }, {
        value: "tangxun",
        label: "塘汛"
    }],
    // 审核状态
    auditStatus: [{
        value: -1,
        label: "全部"
    }, {
        value: 0,
        label: "未审核"
    }, {
        value: 1,
        label: "审核通过"
    }, {
        value: 2,
        label: "审核未通过"
    }],
    // 学校
    school: [{
        value: "shiyan",
        label: "实验"
    }, {
        value: "shengshui",
        label: "圣水"
    }, {
        value: "tongan",
        label: "通安"
    }],
    role: [{
        value: "user",
        label: "用户"
    }],
    // 带多车
    canAny: [{
        value: 1,
        label: "是"
    }, {
        value: 0,
        label: "否"
    },

    ],
    // 考试车状态
    saferStatus: [{
        value: 0,
        label: "正常"
    }, {
        value: 1,
        label: "注销"
    }],
    // vip状态
    vipStatus: [{
        value: 0,
        label: "正常"
    }, {
        value: 1,
        label: "暂停"
    }, {
        value: 2,
        label: "注销"
    }],
    // VIP类型
    vipType: [{
        value: 1,
        label: "教练"
    }, {
        value: 2,
        label: "安全员"
    }],
    // 费用类型
    costType: [{
        value: 0,
        label: "租车"
    }],
    // 是否本校
    isSch: [{
        value: -1,
        label: "全部"
    }, {
        value: 0,
        label: "非本校"
    }, {
        value: 1,
        label: "本校"
    }],
    // 业务状态
    stageStatus: [
        {
            value: -1,
            label: "全部"
        },
        {
            value: 0,
            label: "正常"
        },
        {
            value: 1,
            label: "结算"
        },
        {
            value: 2,
            label: "异常"
        },
        {
            value: 3,
            label: "注销"
        }],
    // 是否已读
    readTag: [{
        value: -1,
        label: "全部"
    }, {
        value: 0,
        label: "未读"
    }, {
        value: 1,
        label: "已读"
    }],
    refoundStatus: [
        {
            value: -1,
            label: "全部"
        }, {
            value: 0,
            label: "未审"
        },
        {
            value: 1,
            label: "审核"
        },
        {
            value: 2,
            label: "驳回"
        }],
    fax: [
        {
            value: '',
            label: "全部"
        },
        {
            value: -1,
            label: "未出票"
        }, {
            value: -2,
            label: "已出票"
        }]
};
