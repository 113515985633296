import request from "../../utils/request";
// 安全员管理

/**
 * 安全员列表
 * @param {object} params
 * @param {string} params.name -非必填
 * @param {string} params.phone - 非必填
 * @param {string} params.starId -非必填
 * @param {int} params.pageIndex  -  必填
 * @param {int} params.pageSize -必填
 * @param {int} params.unitId   -  必填
 * @param {int} params.vehType -  非必填
 */
export function getTeaList(params) {
  return request("/tea/getList", {
    method: "get",
    params: params
  });
}

/**
 * 安全员统计
 * @param {object} params
 * @param {string} params.name -非必填
 * @param {string} params.phone - 非必填
 * @param {string} params.starId -非必填
 * @param {int} params.unitId   -  必填
 * @param {int} params.vehType -  非必填
 */
export function getCount(params) {
  return request("/tea/getCount", {
    method: "get",
    params: params
  });
}

/**
 * 删除安全员
 * @param {object} params
 * @param {string} params.tea  -必填
 * @param {string} params.teaId   安全员ID（int）-必填
 */
export function delTea(params) {
  return request("/tea/del", {
    method: "post",
    params: params
  });
}


/**
 * 新增安全员
 * @param {object} params
 * @param {int} params.canAny   是否可以一带多0 不能 1可以-必填
 * @param {string} params.certId   身份证(18)-必填
 * @param {string} params.name     姓名(15)-必填
 * @param {string} params.phone    电话(15)-必填
 * @param {string} params.starId   星级ID-必填
 * @param {int} params.status  状态 0 正常 1 注销-必填
 */
export function addTea(params) {
  return request("/tea/add", {
    method: "post",
    params: params
  });
}

/**
 * 修改安全员
 * @param {object} params
 * @param {int} params.canAny   是否可以一带多0 不能 1可以-必填
 * @param {string} params.certId   身份证(18)-必填
 * @param {string} params.name     姓名(15)-必填
 * @param {string} params.phone    电话(15)-必填
 * @param {string} params.starId   星级ID-必填
 * @param {int} params.status  状态 0 正常 1 注销-必填
 * @param {int} params.teaId   安全员ID -必填
 * @param {int} params.unitId  单位ID-必填
 * @param {string} params.vehType 车型-必填
 */
export function modifyTea(params) {
  return request("/tea/modify", {
    method: "post",
    params: params
  });
}


// 考试车管理

/**
 * 获取考试车列表
 * @param {object} params
 * @param {int} params.pageIndex    页索引-必填
 * @param {int} params.pageSize     页容量-必填
 * @param {int} params.stage        科目-非必填
 * @param {int} params.starId       星级ID-非必填
 * @param {int} params.status       状态 0 正常 1 注销-非必填
 * @param {int} params.unitId       单位ID-必填
 * @param {int} params.vehNo        车号-非必填
 * @param {string} params.vehType   车型-非必填
 * @param {string} params.vehicle   车型-非必填
 */
export function getvehList(params) {
  return request("/veh/getList", {
    method: "get",
    params: params
  });
}


/**
 * 获取考试车列表总数
 * @param {object} params
 * @param {int} params.stage        科目-非必填
 * @param {int} params.starId       星级ID-非必填
 * @param {int} params.status       状态 0 正常 1 注销-非必填
 * @param {int} params.unitId       单位ID-必填
 * @param {int} params.vehNo        车号-非必填
 * @param {string} params.vehType   车型-非必填
 * @param {string} params.vehicle   车型-非必填
 */
export function getvehCount(params) {
  return request("/veh/getCount", {
    method: "get",
    params: params
  });
}

/**
 * 新增考试车
 * @param {object} params
 * @param {int} params.factory        科目-必填
 * @param {int} params.stage        科目-必填
 * @param {int} params.starId       星级ID-必填
 * @param {int} params.status       状态 0 正常 1 注销-必填
 * @param {int} params.unitId       单位ID-必填
 * @param {int} params.vehNo        车号-必填
 * @param {string} params.vehType   车型-必填
 * @param {string} params.vehicle      车牌号-必填
 */
export function addveh(params) {
  return request("/veh/add", {
    method: "post",
    params: params
  });
}

/**
 * 新增考试车
 * @param {object} params
 * @param {int} params.factory        科目-必填
 * @param {int} params.stage        科目-必填
 * @param {int} params.starId       星级ID-必填
 * @param {int} params.status       状态 0 正常 1 注销-必填
 * @param {int} params.unitId       单位ID-必填
 * @param {int} params.vehNo        车号-必填
 * @param {string} params.vehType   车型-必填
 * @param {string} params.vehicle      车牌号-必填
 * @param {int} params.vehId        车牌ID-必填
 */
export function modifyveh(params) {
  return request("/veh/modify", {
    method: "post",
    params: params
  });
}

/**
 * 注销考试车
 * @param {object} params
 * @param {int} params.unitId       单位ID-必填
 * @param {string} params.vehicle      车牌号-必填
 * @param {int} params.vehId        车牌ID-必填
 */
export function delveh(params) {
  return request("/veh/del", {
    method: "post",
    params: params
  });
}


/**
 * 获取VIP列表总数
 * @param {object} params
 * @param {string} params.name         姓名-非必填
 * @param {string} params.phone        电话-非必填
 * @param {int} params.unitId       单位ID-必填
 */
export function getVipCount(params) {
  return request("/vip/getCount", {
    method: "get",
    params: params
  });
}

/**
 * 获取VIP列表
 * @param {object} params
 * @param {string} params.name         姓名-非必填
 * @param {string} params.phone        电话-非必填
 * @param {int} params.pageIndex    页索引-必填
 * @param {int} params.pageSize     页容量-必填
 * @param {int} params.unitId       单位ID-必填
 */
export function getVipList(params) {
  return request("/vip/getList", {
    method: "get",
    params: params
  });
}

/**
 * 新增VIP
 * @param {object} params
 * @param {string} params.certId       身份证-必填
 * @param {string} params.name         姓名-必填
 * @param {string} params.phone        电话-必填
 * @param {int} params.shortId         所属驾校ID-必填
 * @param {int} params.status          状态 0 正常 1 暂停 2 注销(int)
 * @param {int} params.unitId          单位ID-必填
 * @param {int} params.userId          申请人ID，如微信申请为空字符-必填
 * @param {int} params.valid           审核结果 0 未审核 1 通过 2 未通过-必填
 * @param {string} params.vipType      VIP类型 0 一般 1 教练 2 安全员-必填
 */
export function addVip(params) {
  return request("/vip/add", {
    method: "post",
    params: params
  });
}


/**
 * 修改VIP
 * @param {object} params
 * @param {string} params.certId       身份证-必填
 * @param {string} params.name         姓名-必填
 * @param {string} params.phone        电话-必填
 * @param {int} params.shortId         所属驾校ID-必填
 * @param {int} params.status          状态 0 正常 1 暂停 2 注销(int)
 * @param {int} params.unitId          单位ID-必填
 * @param {int} params.userId          申请人ID，如微信申请为空字符-必填
 * @param {int} params.valid           审核结果 0 未审核 1 通过 2 未通过-必填
 * @param {string} params.vipType      VIP类型 0 一般 1 教练 2 安全员-必填
 */
export function modifyVip(params) {
  return request("/vip/modify", {
    method: "post",
    params: params
  });
}

/**
 * 注销VIP
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.unitId          单位ID-必填
 */
export function delVip(params) {
  return request("/vip/del", {
    method: "post",
    params: params
  });
}

/**
 * 暂停VIP /vip/start
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.unitId          单位ID-必填
 */
export function pauseVip(params) {
  return request("/vip/pause", {
    method: "post",
    params: params
  });
}

/**
 * 启用VIP
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.unitId          单位ID-必填
 */
export function startVip(params) {
  return request("/vip/start", {
    method: "post",
    params: params
  });
}


/**
 * 审核VIP
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.unitId          单位ID-必填
 * @param {int} params.valid           审核结果 0 未审核 1 通过 2 未通过
 * @param {int} params.validUserId     审核人
 */
export function validVip(params) {
  return request("/vip/valid", {
    method: "post",
    params: params
  });
}


/**
 * 选择Vip推荐人
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.referee2           单位ID-必填
 * @param {int} params.referee3            审核结果 0 未审核 1 通过 2 未通过
 * @param {int} params.unitId      审核人
 */
export function installReferee(params) {
  return request("/vip/installReferee", {
    method: "post",
    params: params
  });
}

/**
 * 撤销vip推荐人
 * @param {object} params
 * @param {string} params.phone        电话-必填
 * @param {int} params.referee2           单位ID-必填
 * @param {int} params.referee3            审核结果 0 未审核 1 通过 2 未通过
 * @param {int} params.unitId      审核人
 */
export function removeReferee(params) {
  return request("/vip/removeReferee", {
    method: "post",
    params: params
  });
}

/**
 * 人车绑定
 * @param {object} params
 * @param {int} params.teaId        安全员ID-必填
 * @param {int} params.unitId            单位ID-必填
 * @param {string} params.veh             车牌号-必填
 * @param {int} params.vehId       车辆ID-必填
 */
export function addManCarBind(params){
  return request("/veh/bind",{
    method:"post",
      params:params
  });
}
/**
 * 人车解绑
 * @param{object} params
 * @param{int} params.unitId  单位ID
 * @param{string} params.veh  车号
 * @param{int} params.vehId  车辆ID
 */
export function unBind(params) {
  return request("/veh/unBind",{
     method:"post",
      params:params
    });
}
/**
 * 控制日志
 * @param{object} params
 * @param{string} params.begin   开始时间
 * @param{string} params.end     结束时间
 * @param{int} params.unitId    单位ID
 * @param{int} params.vehId   车辆ID
 */

export function getControlLog(params) {
  return request("/monitor/getControlLogByVehId",{
    method:"get",
      params:params
  })
}
/**
 * 控制日志根据车辆ID
 * @param{object} params
 * @param{string} params.begin   开始时间
 * @param{string} params.end     结束时间
 * @param{int} params.unitId    单位ID
 * @param{int} params.userId   用户ID
 */

export function getControlLogByUserId(params) {
    return request("/monitor/getControlLogByAll",{
        method:"get",
        params:params
    })
}
