export default {
    name: "tree-menu",
    props: {
        column: {
            type: Array,
            default: []
        },
        tableList: {
            type: Array,
            default: []
        },
        cType: {
            type: String,
            default: '0'
        },
        height:{
            type:String,
            default:'calc(100vh - 300px)'
        }
    },
    data() {
        return {
            screenHeight: 0,
            dataSource: [],
            total: 0,
            once: 20,
            loopCount: 0,
            countOfRender: 0,
            newcolumn: [],
            loadDate: [],
            ul: ''
        };
    },
    computed: {},
    methods: {
        // 分段渲染数据
        stepByDate(tabledate) {
            setTimeout(() => {
                $('#teaTable tbody').html('');
                // 插入十万条数据
                this.loadDate = tabledate;
                this.total = tabledate.length;
                // 一次插入 20 条，如果觉得性能不好就减少
                this.once = 30;
                // 渲染数据总共需要几次
                this.loopCount = this.total / this.once
                this.countOfRender = 0
                this.ul = document.getElementById("teaTbo");
                this.loop();
            }, 0);
        },
        add() {
            const fragment = document.createDocumentFragment();
            for (let i = 0; i < this.once; i++) {
                let li = this.createTr(this.loadDate[this.countOfRender * this.once + i]);
                fragment.appendChild(li);
            }
            this.ul.appendChild(fragment);
            this.countOfRender += 1;
        },
        loop() {
            if (this.countOfRender < this.loopCount) {
                this.add();
                // 自动更新此处情况不适合
                // window.requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
                //     window.setTimeout(callback, 1000 / 60);
                // };
                // var requestID = window.requestAnimationFrame(this.add)
            } else {
                // console.log('come in', requestID);
                // var cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
                // cancelAnimationFrame(requestID);
            }
        },
        // 创建行
        createTr(date) {
            let row = document.createElement('tr'); //创建行 
            for (var key in date) {
                // console.log(key, date[key])
                let a = document.createElement('td'); //创建第一列id 
                a.innerHTML = date[key]; //填充数据 
                row.appendChild(a); //加入行 ，下面类似 
            }
            return row;
        },
        getThead(val) {
            $('#teaTable thead').html('');
            var str = '<tr>';
            for (let i = 0; i < val.length; i++) {
                if (val[i]) {
                    str += `<th><div>${val[i].title}</div></th>`
                }
            }
            str = str + '</tr>';
            $('#teaTable thead').append(str);
        },
        getNewObj() {
            let objKey = this.newcolumn.map(e => {
                if (e.dataIndex) {
                    return e.dataIndex;
                } else {
                    return 'SerialNumber'
                }

            });
            return objKey;

        },
        getCtype(val) {
            let keys = this.getNewObj().filter(item => { return item });
            var date = val.map((item, index) => {
                item.SerialNumber = index + 1;
                let getNewObj = {};
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] == "cost" || keys[i] == 'couponPrice') {
                        let cost = item[keys[i]] / 100;
                        getNewObj[keys[i]] = cost;
                    } else if (keys[i] == "stuName") {
                        let stuName = item[keys[i]] ? item[keys[i]] : '本校学员';
                        getNewObj[keys[i]] = stuName;
                    }
                    else if (keys[i] == "smallVehStage2" || keys[i] == "bigVehStage2" || keys[i] == "smallVehStage3" || keys[i] == "bigVehStage3") {
                        let timer = item[keys[i]] / 60;
                        getNewObj[keys[i]] = timer
                    }
                    else if (keys[i] == "trainTime") {
                        let mini = `${parseInt(item[keys[i]])}分钟`;
                        getNewObj[keys[i]] = mini
                    }
                    else if (keys[i] == "referee") {
                        item[keys[i]] = item[keys[i]] ? item[keys[i]] : '无';
                        getNewObj[keys[i]] = item[keys[i]]
                    } else {
                        getNewObj[keys[i]] = item[keys[i]]
                    }

                }
                return getNewObj;
            })
            if (this.cType == '2') {
                this.dataSource = date.filter(obj => {
                    delete (obj["cType"]);
                    return obj;
                })
            } else {
                this.dataSource = date.filter(obj => {
                    delete (obj["coupon"]);
                    delete (obj["couponPrice"]);
                    delete (obj["cType"]);
                    return obj;
                })
            }
            this.stepByDate(this.dataSource);

        },
        // 滚动加载
        scroll() {
            if ($("#teaTbo").length) {
                var tbodyC = $("#teaTbo")[0].scrollHeight;
                var tbodyH = $('#teaTbo').height();
                var tbodyS = $('#teaTbo').scrollTop();
            }
            if (tbodyS + tbodyH + 2 >= tbodyC) {
                this.loop();
            }
        }
    },
    created() { },
    watch: {
        tableList: {
            handler(val, oldval) {
                $('#teaTbo').animate({scrollTop:0},10);
                if (val.length) {
                    this.getCtype(val)
                } else {
                    $('#teaTable tbody').html('');
                    let str = '<div class="placeholder">暂无数据</div>'
                    $('#teaTbo').append(str);
                }

            }
        },
        column: {
            handler(val, oldval) {
                this.newcolumn = val;
                this.getThead(val);
            }
        }
    },
    mounted() {
        this.getThead(this.column);
        this.newcolumn = this.column;
        this.screenHeight = document.documentElement.scrollHeight - 300;
        window.addEventListener('scroll', this.scroll, true);
    }

};