<template>
  <a-locale-provider :locale="locale">
    <section id="app">
      <router-view></router-view>
    </section>
  </a-locale-provider>
</template>
<script>
// 引入ant最初为英文形式，需要转换
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
   data() {
    return {
      locale: zhCN
    };
   }
}
</script>

<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
