import axios from "axios";
import Vue from "vue";
export default {
    name: "content-header",
    //公共header
    data() {
        return {
            weatherData:[]
        };
    },
    computed: {},
    methods: {
        cityWeather(city) {
            // 清空对象
            this.$http.jsonp('http://api.map.baidu.com/telematics/v3/weather', //jsonp参数一: 跨域请求接口;
                {
                    params: {
                        location: city,
                        output: "json",
                        ak: "puqTvZGk15LZHcEkskSjzPkC" // 百度地图ak
                    },
                    jsonp: 'callback'
                }, 
            ).then( res => {
                this.weatherData = res.data.results;
            }, err => {
                console.log('err', err);
            });
        }
    },
    created() {},
    mounted() {
        this.cityWeather('成都');
    }
};